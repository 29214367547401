import React from 'react'
import './cookiesp.scss'
import { useTranslation } from 'react-i18next';
import back_img from '../../images/404img_2.png'
function CookiesP() {
    const { t, i18n } = useTranslation();
  return (
    <div id='cookiesp' style={{background:`linear-gradient(rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.7) 100%),url(${back_img})`}}>
      <h2>{t('CookiesPageH')}</h2>
      <p>{t('CookiesPageP1')}<b>BT Agency business travel and event</b>{t('CookiesPageP2')}</p>
      <div className='cookieslist'>
        <div>
            <h2>1. {t('CookiesPageEl1H')}</h2>
            <div className='cookiescard'>{t('CookiesPageEl1P1')}</div>
        </div>
        <div>
            <h2>2. {t('CookiesPageEl2H')}</h2>
            <div className='cookiescard'>
                <ul>
                    <li><b>{t('CookiesPageEl2P1S')}</b> {t('CookiesPageEl2P1')}</li>
                    <li><b>{t('CookiesPageEl2P2S')}</b> {t('CookiesPageEl2P2')}</li>
                    <li><b>{t('CookiesPageEl2P3S')}</b> {t('CookiesPageEl2P3')}</li>
                </ul>
            </div>
        </div>
        <div>
            <h2>3. {t('CookiesPageEl3H')}</h2>
            <div className='cookiescard'>{t('CookiesPageEl3P1')}</div>
        </div>
        <div>
            <h2>4. {t('CookiesPageEl4H')}</h2>
            <div className='cookiescard'>{t('CookiesPageEl4P1')}</div>
        </div>
      </div>
    </div>
  )
}

export default CookiesP
