import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './youtubevideos.scss';
import video_img from '../../images/video_play.png';
import heading_video from '../../images/video_2024-10-25_09-29-26.mp4';
import video_back from '../../images/video_back_img.png'
import InProgress from "../inprogress/InProgress";
import { useTranslation } from 'react-i18next';
import video_poster from '../../images/video_poster.png'

const YoutubeVideos = ({ channelId }) => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const API_KEY = "AIzaSyArJ4CTUZQ4V0Yub42TgryVFJmFHppI1vU";
  const MAX_RESULTS = 6;

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${channelId}&part=snippet,id&order=date&maxResults=${MAX_RESULTS}`
        );
        setVideos(response.data.items);
      } catch (error) {
        console.error("Error fetching videos", error);
      }
    };

    fetchVideos();
  }, [channelId]);

  const handleVideoClick = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('uk-UA');
  };

  const decodeHTMLEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  return (
    <div id="youtubevideos" >
      <div className="heading_video">
        <div>
          <h1>{t('blogHeadinH')}</h1>
          <p>{t('blogHeadinP')}</p>
          <button>{t('gotoblogBtn')}<span style={{fontSize:'25px'}}> →</span></button>
        </div>
        <div onClick={handleVideoClick} className="video-container">
          <video ref={videoRef} loop poster={video_poster}>
            <source src={heading_video} type="video/mp4" />
            Ваш браузер не підтримує відтворення відео.
          </video>
          {!isPlaying && <img className="video_icon" src={video_img} alt="Play Icon" />}
        </div>
      </div>
      <InProgress/>
      {/*<h1><span>You should</span><br />check it out</h1>
      <div className="videos_grid">
        {videos.map((video) => (
          <div className="videos_grid_element" key={video.id.videoId}>
            <h2>{decodeHTMLEntities(video.snippet.title)}</h2>
            <p>{decodeHTMLEntities(video.snippet.description)}</p>
            <p className="date">{formatDate(video.snippet.publishedAt)}</p>
            <a href={`https://www.youtube.com/watch?v=${video.id.videoId}`} target="_blank" rel="noopener noreferrer">
              <div>
                <img className="video_thumbnails" src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
                <img className="video_icon" src={video_img} alt="Play Icon" />
              </div>
            </a>
          </div>
        ))}
      </div>*/}
    </div>
  );
};

export default YoutubeVideos;
