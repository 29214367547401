import React from 'react'
import InPrpress from '../inprogress/InProgress'
function SignIn() {
  return (
    <div>
      <InPrpress/>
    </div>
  )
}

export default SignIn
