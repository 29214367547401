import React from 'react';
import './App.css';
import './i18n'; // Import i18n configuration
import { useMetadata } from './useMetadata'; // Import the new hook
import { Routes, Route } from "react-router-dom";
import Layout from './pages/layout/Layout';
import Index_P from './components/outlet/Index_P';
import Events from './pages/events/P_Events';
import AboutUs from './pages/aboutus/AboutUs';
import OurJobs from './pages/ourjobs/OurJobs';
import Blog from './pages/blog/Blog';
import OurServices from './pages/ourservices/OurServices';
import GetQuoteP from './pages/quotep/GetQuoteP';
import OurProjectsP from './pages/ourprojects/OurProjectsP';
import CookiesP from './pages/CookiesP/CookiesP';
import PrivacyPolicyP from './pages/privacypolicyp/PrivacyPolicyP';
import SignIn from './components/signin/SignIn';

import { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function App() {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  
  // Use the metadata hook
  useMetadata();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Extract language from URL
  const currentLanguage = pathname.split('/')[1];
  
  // Set language if it's valid
  useEffect(() => {
    if (['en', 'ua'].includes(currentLanguage)) {
      i18n.changeLanguage(currentLanguage);
    }
  }, [currentLanguage, i18n]);

  return (
    <div className="App">
      <Routes>
        {/* Redirect root to default language */}
        <Route path="/" element={<Navigate to="/ua/" replace />} />
        
        {/* Language-specific routes */}
        <Route path="/:lang/" element={<Layout />}>
          <Route index element={<Index_P />} />
          <Route path="events" element={<Events />} />
          <Route path="about_us" element={<AboutUs />}/>
          <Route path='our_jobs' element={<OurJobs/>}/>
          <Route path='blog' element={<Blog/>}/>
          <Route path='our_services' element={<OurServices/>} />
          <Route path='get_quote' element={<GetQuoteP/>}/>
          <Route path='our_projects' element={<OurProjectsP/>}/>
          <Route path='cookies' element={<CookiesP/>}/>
          <Route path='privacy_policy' element={<PrivacyPolicyP/>}/>
          <Route path='sign_in' element={<SignIn/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;