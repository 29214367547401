import React, { useEffect } from 'react';
import './cookiealert.scss';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CookieAlert() {
    const { t } = useTranslation();
    const location = useLocation();

    // Check localStorage for cookie acceptance and set up classes on mount
    useEffect(() => {
        const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
        const cookieAlert = document.getElementById('cookiealert');

        if (hasAcceptedCookies || location.pathname === '/cookies') {
            cookieAlert.classList.add('disabled');
        }else {cookieAlert.classList.remove('disabled');}
    }, [location.pathname]);

    // Handle accepting cookies and saving to localStorage
    const handleAcceptCookies = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        document.getElementById('cookiealert').classList.add('disabled');
    };
    const handleDenyCookies = () => {
        localStorage.setItem('cookiesAccepted', 'false');
        document.getElementById('cookiealert').classList.add('disabled');
    };

    return (
        <div id='cookiealert' className='cookiealert'>
            <div>
                <h3>{t('CookieAlertH')}</h3>
                <p>{t('CookieAlertP')}</p>
                <div>
                    <button onClick={handleAcceptCookies}>{t('CookieAlertAcceptBtn')}</button>
                    <button onClick={handleDenyCookies}>{t('CookieAlertDenyBtn')}</button>
                    <Link to='/cookies'>
                        {t('CookieAlertLearnMore')}<span style={{ fontSize: '25px' }}> →</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CookieAlert;
