import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useMetadata = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Update document title
    document.title = t('metadata.title');

    // Update language attribute on html tag
    document.documentElement.lang = i18n.language;

    // Update description meta tag
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', t('metadata.description'));
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = t('metadata.description');
      document.head.appendChild(newMetaDescription);
    }

    // Update keywords meta tag
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.setAttribute('content', t('metadata.keywords'));
    } else {
      const newMetaKeywords = document.createElement('meta');
      newMetaKeywords.name = 'keywords';
      newMetaKeywords.content = t('metadata.keywords');
      document.head.appendChild(newMetaKeywords);
    }

    // Add hreflang links for language alternatives
    const existingHrefLangs = document.querySelectorAll('link[rel="alternate"]');
    existingHrefLangs.forEach(link => link.remove());

    const currentUrl = window.location.href;
    
    const hrefLangEn = document.createElement('link');
    hrefLangEn.rel = 'alternate';
    hrefLangEn.hreflang = 'en';
    hrefLangEn.href = currentUrl.replace('/ua/', '/en/');

    const hrefLangUa = document.createElement('link');
    hrefLangUa.rel = 'alternate';
    hrefLangUa.hreflang = 'uk';
    hrefLangUa.href = currentUrl.replace('/en/', '/ua/');

    document.head.appendChild(hrefLangEn);
    document.head.appendChild(hrefLangUa);

  }, [t, i18n.language]);
};