import React from 'react'
import './aboutus.scss'
import AboutHeading from '../../components/aboutheading/AboutHeading'
import OurMission from '../../components/ourmission/OurMission'
import OurTeam from '../../components/ourteam/OurTeam'
import Header from '../../components/header/Header'
import team_img_1 from '../../images/team_img_1.jfif';
import CustomSplide from '../../components/customsplide/CustomSplide'
function AboutUs() {
  
  return (
    <div id='aboutus'>
      <AboutHeading/>
      <CustomSplide/>
      <OurMission/>

      <OurTeam/>
    </div>
  )
}

export default AboutUs
