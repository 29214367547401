import React from 'react'
import './aboutheading.scss'
import image3 from '../../images/image3.png'
import background_img_1 from '../../images/background_image_22.png'
import { useTranslation } from 'react-i18next';
function AboutHeading() {
  const { t, i18n } = useTranslation();
  return (
    <div id='aboutheading' style={{background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.9) 85%, rgba(255, 255, 255, 0.95) 90%, rgba(255, 255, 255, 1) 100%), url(${background_img_1}) no-repeat center top`}}>
      <div>
      <h1>{t('we')}</h1>
      <h2>{t('aboutusheadingh1')}</h2>
      <p className='subheading'>{t('aboutusheadingp1')}</p>
      <p>{t('aboutusheadingp2')}</p>
      <p>{t('aboutusheadingp3')}</p>

    </div>
    </div>
  )
}

export default AboutHeading
