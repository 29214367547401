import React from 'react'
import './privacypolicyp.scss'
import { useTranslation } from 'react-i18next';
import back_img from '../../images/404img_2.png'
function PrivacyPolicyP() {
    const { t, i18n } = useTranslation();
  return (
    <div id='privacypolicyp' style={{background:`linear-gradient(rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.7) 100%),url(${back_img})`}}>
      <h2>{t('PrivacyPolicyH')}</h2>
      <p>{t('PrivacyPolicyP1')}<b>BT Agency business travel and event</b>{t('PrivacyPolicyP2')}</p>
      <div className='privacypolicylist'>
        <div>
            <h2>1. {t('PrivacyPolicyEl1H')}</h2>
            <div className='privacypolicycard'>
            <ul>
                    <li>{t('PrivacyPolicyEl1P1')}</li>
                    <li>{t('PrivacyPolicyEl1P2')}</li>
                </ul>
            </div>
        </div>
        <div>
            <h2>2. {t('PrivacyPolicyEl2H')}</h2>
            <div className='privacypolicycard'>
                <ul>
                    <li>{t('PrivacyPolicyEl2P1')}</li>
                    <li>{t('PrivacyPolicyEl2P2')}</li>
                    <li>{t('PrivacyPolicyEl2P3')}</li>
                </ul>
            </div>
        </div>
        <div>
            <h2>3. {t('PrivacyPolicyEl3H')}</h2>
            <div className='privacypolicycard'>
            <ul>
                    <li>{t('PrivacyPolicyEl3P1')}</li>
                    <li>{t('PrivacyPolicyEl3P2')}</li>
                </ul>
            </div>
        </div>
        <div>
            <h2>4. {t('PrivacyPolicyEl4H')}</h2>
            <div className='privacypolicycard'>
            <ul>
                  
                    <li>{t('PrivacyPolicyEl4P1')}</li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyP
