import React, { useState } from 'react';
import './faq.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation();

  const toggleFAQ = (index) => {
    // If the same question is clicked, hide it; otherwise, set as active
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id='faq'>
      <div>
        <div>
          <h1>FAQs</h1>
          <hr />
        </div>
        <p>{t('faqp')}</p>
      </div>
      <div>
        <ul>
          {[1, 2, 3, 4].map((i) => (
            <li
              key={i}
              onClick={() => toggleFAQ(i)}
              className={activeIndex === i ? 'active' : ''}
            >
              {t(`faqq${i}`)}
              <div className={`faqa ${activeIndex === i ? '' : 'hidden'}`}>
                {t(`faqa${i}`)}
              </div>
            </li>
          ))}
        </ul>
        <Link to='about_us'>{t('MoreBtn')}<span style={{ fontSize: '24px' }}> →</span></Link>
      </div>
    </div>
  );
}

export default Faq;
