import React from 'react'
import InProgress from '../../components/inprogress/InProgress'

function OurProjectsP() {
  return (
    <div style={{marginTop:'120px'}}>
      <InProgress/>
    </div>
  )
}

export default OurProjectsP
