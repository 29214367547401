import React from 'react'
import './footer.scss'
import logo from '../../logo3.png'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function Footer() {
  const { t, i18n } = useTranslation();
  
  return (
    <div id='footer'>
      <div className='footer-links'>
        
        <div>
          <h5>{t('footerCompany')}</h5>
          <ul>
          <li><Link to="/blog">{t('footerBlog')}</Link></li>
          <li><Link to='/our_jobs'>{t('footerJobs')}</Link></li>
          </ul>
        </div>
       
        <div>
          <h5>{t('footerlegal')}</h5>
          <ul>
            <li><Link to='privacy_policy'>{t('footerPrivacy')}</Link></li>
            <li><Link to='cookies'>{t('footerCookies')}</Link></li>
          </ul>
        </div> 
        <div>
          <h5>{t('footerContact')}</h5>
          <ul>
          <li><a href="mailto:contact@businesstravel.com.ua">contact@businesstravel.com.ua</a></li>
          <li><a href="tel:+380673152777">+380673152777</a></li>
          <li><a>{t('footerAdress')}</a></li>
          </ul>
        </div>
      </div>
      <ul className='contacts-sm'>
      <li><a href="mailto:contact@businesstravel.com.ua">contact@businesstravel.com.ua</a></li>
          <li><a href="tel:+380673152777">+380673152777</a></li>
          <li><a>{t('footerAdress')}</a></li>
      </ul>
      <div>
       
        <img src={logo} fill alt="" />
        <div className='footer-medias'>
        <a href='https://t.me/+380673152777'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" width="496" height="512"><path d="M248 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm121.8 169.9-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z" fill="#fff"/></svg></a>
          <a href='https://www.instagram.com/businesstravel.com.ua/profilecard/?igsh=MWNkaTg2N3EwYjlwaA==' target='_blank'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24" class="um-icon _2cd4f8c5"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6a74.8 74.8 0 1 1 .1-149.3 74.8 74.8 0 0 1-.1 149.3zm146.4-194.3a26.7 26.7 0 1 1-53.6 0 26.8 26.8 0 0 1 53.6 0zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388a75.6 75.6 0 0 1-42.6 42.6c-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9A75.6 75.6 0 0 1 49.4 388c-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1A75.6 75.6 0 0 1 92 81.2c29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9a75.6 75.6 0 0 1 42.6 42.6c11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" fill="#fff"></path></svg></a>
          <a href='https://www.linkedin.com/in/veronika-tomashevska-ab6806185/' target='_blank'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="448" height="512"><path d="M416 32H31.9A32.1 32.1 0 0 0 0 64.3v383.4A32.1 32.1 0 0 0 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3A32.2 32.2 0 0 0 416 32zM135.4 416H69V202.2h66.5V416zm-33.2-243a38.5 38.5 0 1 1 0-77 38.5 38.5 0 0 1 0 77zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" fill="#fff"/></svg></a>
          <a><svg xmlns="http://www.w3.org/2000/svg" fill='#fff' shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 462.799"><path fill-rule="nonzero" d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"/></svg></a>
        </div>
         <p>© 2024 bt agency business travel and event.TM</p>
      </div>
      
    </div>
  )
}

export default Footer
