import React from 'react'
import './ourmission.scss'
import back_img_3 from '../../images/background_image_3.png'
import { useTranslation } from 'react-i18next';
function OurMission() {
  const { t, i18n } = useTranslation();
  return (
    <div id='ourmission'>
      <h1><span>{t('our')} </span>{t('values')}</h1>
      <div class="ourmission-grid-container">
        <div class="ourmission-grid-item">
          <div>
            <span>1</span>
          <h2>{t('ourvaluesh1')}</h2>
          </div>
          
          <p>{t('ourvaluesp1')}</p>
        </div>
        
        <div class="ourmission-grid-item">
          <div>
            <span>3</span>
          <h2>{t('ourvaluesh3')}</h2>
          </div>
          
          <p>{t('ourvaluesp3')}</p>
        </div>
        <div class="ourmission-grid-item">
          <div>
            <span>2</span>
          <h2>{t('ourvaluesh2')}</h2>
          </div>
          
          <p>{t('ourvaluesp2')}</p>
        </div>
        <div class="ourmission-grid-item">
          <div>
            <span>4</span>
           <h2>{t('ourvaluesh4')}</h2>
          </div>
         
          <p>{t('ourvaluesp4')}</p>
        </div>
      </div>
      <img src={back_img_3} alt='our values image'></img>
    </div>
  )
}

export default OurMission
