import React, { useRef } from 'react';
import './getquotep.scss';
import Header from '../../components/header/Header';
import Slider from "react-slick";
import Emailform from '../../components/emailform/Emailform';
import quote_img_1 from '../../images/quote/quote_img_1.png';
import quote_img_2 from '../../images/quote/quote_img_2.png';
import quote_img_3 from '../../images/quote/quote_img_3.png';
import { useTranslation } from 'react-i18next';

function GetQuoteP() {
  const { t, i18n } = useTranslation();
  const emailFormRef = useRef(null); // Create a reference for Emailform
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
  };
  
  const scrollToEmailForm = () => {
    emailFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div id='quotep'>
      
      <div className='quotep-heading'>
        <div className='quotep-heading-grid'>
          <div style={{ background: `url(${quote_img_1})` }} className='quotep-heading-grid-child'>
         
            <h3>{t('quotepCard1H')}</h3>
            <p>{t('quotepCard1P')}</p>
          </div>
          <div style={{ background: `url(${quote_img_2})` }} className='quotep-heading-grid-child'>
            <h3>{t('quotepCard2H')}</h3>
            <p>{t('quotepCard2P')}</p>
          </div>
          <div style={{ background: `url(${quote_img_3})` }} className='quotep-heading-grid-child'>
            <h3>{t('quotepCard3H')}</h3>
            <p>{t('quotepCard3P')}</p>
          </div>
          <div style={{ background: `url(${quote_img_2})` }} className='quotep-heading-grid-child'>
            <h3>{t('quotepCard4H')}</h3>
            <p>{t('quotepCard4P')}</p>
          </div>
        </div>
        <div className='quotep-heading-text'>
          <h1>{t('quotepH')}<span>{t('quotepHSpan')}</span></h1>
          <h1><span>{t('quotepStep')} 1</span></h1>
          <h2>{t('quotepStep1H')}</h2>
          <p>{t('quotepStep1P')}</p>
          <button onClick={scrollToEmailForm}>Next</button>
        </div>
      </div>
      <div ref={emailFormRef}> 
        <Emailform />
      </div>
    </div>
  );
}

export default GetQuoteP;
