import React from "react";
import "./gridcomponent.scss";

const GridComponent = ({ items, columns, layoutType, height  }) => {
  return (
    <div style={{height}} className={`grid-container ${columns === 2 ? layoutType : 'three-columns'}`}>
      {items.map((item, index) => (
        <div key={index} className={`grid-item ${index === 0 ? "left" : index === items.length - 1 ? "right" : "middle"}`}>
          <h3>{item.title}</h3>
          <p>{item.text}</p>
          <img src={item.image} alt={item.title} style={{ height: item.imageHeight }} />
        </div>
      ))}
    </div>
  );
};

export default GridComponent;
