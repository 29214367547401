import React from 'react'
import { Link } from 'react-router-dom';
import './quote.scss'
import { useTranslation } from 'react-i18next';
import back_img from '../../images/home_quote_back_img.jfif'
function Quote() {
  const { t, i18n } = useTranslation();

  return (
    <div style={{background:` linear-gradient(to left, rgba(91, 95, 98, 0.7) 0%, rgba(91, 95, 98, 0.7) 100%), url(${back_img})`}} id='quote'>
      <h1>{t('getQuoteHeader')}</h1>
      <p>{t('getQuoteText')}</p>
      <Link to='/get_quote'>{t('getQuoteButton2')}<span style={{fontSize:'28px'}}> →</span></Link>
    </div>
  )
}

export default Quote
