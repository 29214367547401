import React from 'react'
import './features.scss'
import { useTranslation } from 'react-i18next';
function Features() {
  const { t, i18n } = useTranslation();
  return (
    <div id='features'>
      <div className='wcu'>
      <div className='why'>{t('why')}</div>
      <div className='choose-us'><span>{t('choose')} </span><span>{t('us')}</span></div>
      </div>
     <div className='why c'>{t('why')}</div>
     <div className='feature_card'><h5>{t('wcuCard1Header')}</h5><p>{t('wcuCard1text')}</p></div>
     <div className='feature_card'><h5>{t('wcuCard2Header')}</h5><p>{t('wcuCard2text')}</p></div>
     <div className='feature_card'><h5>{t('wcuCard3Header')}</h5><p>{t('wcuCard3text')}</p></div>
     <div className='feature_card'><h5>{t('wcuCard4Header')}</h5><p>{t('wcuCard4text')}</p></div>
     <div className='eeeee'>
     <div className='choose-us c'><span>{t('choose')} </span><span>{t('us')}</span></div>
    <div className='feature_card sm' ><h5>{t('wcuCard5Header')}</h5><p>{t('wcuCard5text')}</p></div>
     </div>
     <div className='feature_card'><h5>{t('wcuCard6Header')}</h5><p>{t('wcuCard6text')}</p></div>
     <div className='feature_card'><h5>{t('wcuCard7Header')}</h5><p>{t('wcuCard7text')}</p></div>
    </div>
  )
}

export default Features
