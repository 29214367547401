import React, { useState, useEffect } from 'react';
import Header from '../../components/header/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Emailform from '../../components/emailform/Emailform';
import CookieAlert from '../../components/CookieAlert/CookieAlert';

function Layout() {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showCookieAlert, setShowCookieAlert] = useState(false);
  const navigate = useNavigate();

  const toggleEmailForm = () => {
    setShowEmailForm(!showEmailForm);
  };

  const scrollToFaq = () => {
    navigate('/', { replace: true });
    setTimeout(() => {
      const faqElement = document.getElementById('faq');
      faqElement?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const scrollToFooter = () => {
    const footerElement = document.getElementById('footer');
    footerElement?.scrollIntoView({ behavior: 'smooth' });
  };

  // Show CookieAlert after 0.1 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCookieAlert(true);
    }, 700);
    return () => clearTimeout(timer); // Clear timeout on unmount
  }, []);

  return (
    <div id='layout'>
      <Header themecolor='#000000' toggleFaq={scrollToFaq} />
      <div className={`emailform ${showEmailForm ? 'visible' : ''}`}>
        {showEmailForm && <Emailform toggleEmailForm={toggleEmailForm} />}
      </div>
      <Outlet context={{ scrollToFooter }} /> {/* Pass scrollToFooter function via Outlet */}
      {showCookieAlert && <CookieAlert />}
      <Footer />
    </div>
  );
}

export default Layout;
