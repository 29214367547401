import React, { useRef } from 'react';
import './index_p.scss'
import img1 from '../../images/image1.svg'
import Partners from '../partners/Partners'
import Services from '../services/Services'
import Numbers from '../numbers/Numbers'
import Features from '../features/Features'
import Quote from '../quote/Quote'
import Faq from '../faq/Faq'
import backimg from '../../images/background_image.png'
import { useTranslation } from 'react-i18next';
import Header from '../header/Header'
import { useOutletContext } from 'react-router-dom';
function Index_P() {
  const { t, i18n } = useTranslation();
  const { scrollToFooter } = useOutletContext();
  
  return (
    
    <div id='index_p'>

      
      <div className='plane' style={{background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 1) 100%), url(${backimg})`}}>
      <div className='learn-more'>

          
          <h1>{t('titleH1')}</h1>
          <h1>{t('titleH2')}</h1>
          <h5>{t('titlep1')}</h5>
          <p>{t('titlep2')}</p>
          <div>
            <button onClick={scrollToFooter}>{t('contactExpert')}<span style={{fontSize:'25px'}}> →</span></button>
          </div>


      </div>

      <Features/>
      </div>
      <Quote/>
      
        <Faq/>
      
    </div>
  )
}

export default Index_P
