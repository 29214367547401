import React from 'react'
import './ourjobs.scss'
import Header from '../../components/header/Header'
import InProgress from '../../components/inprogress/InProgress'

function OurJobs() {
  
  return (
    <div id='ourjobs'>
      
      <InProgress/>
    </div>
  )
}

export default OurJobs
