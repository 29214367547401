import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import './Header.scss';
import logo from '../../logo.svg';
import logo2 from '../../logo2.png';
import { useTranslation } from 'react-i18next';

function Header({ toggleEmailForm, themecolor, toggleFaq }) {
  const { t, i18n } = useTranslation();
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [currentLogo, setCurrentLogo] = useState(logo);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 850);
  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuActive(prevState => !prevState);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuActive(false);
  };

  const handleOurServicesClick = () => {
    closeMobileMenu();
    if (location.pathname === '/ua/our_services' || location.pathname === '/en/our_services') {
      window.location.reload();
    }
  };

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'ua' ? 'en' : 'ua';
    i18n.changeLanguage(newLanguage);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 850);
      setCurrentLogo(window.innerWidth <= 500 ? logo2 : logo);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const shouldAddScrolledClass = isScrolled || (location.pathname === '/blog' && isMobileView);
  const textColor = shouldAddScrolledClass ? '#FFFFFF' : ((location.pathname === '/ua/' || location.pathname === '/en/') ? '#FFFFFF' : themecolor);

  return (
    <div id="header" className={shouldAddScrolledClass ? 'scrolled' : ''}>
      <Link to={'/'}><img src={currentLogo} alt="logo" /></Link>
      <div className='header-menu'>
        <div className='header-buttons'>
          <button onClick={toggleLanguage}><svg width="26px" height="26px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 12C21 16.9706 16.9706 21 12 21M21 12C21 7.02944 16.9706 3 12 3M21 12H3M12 21C7.02944 21 3 16.9706 3 12M12 21C12 21 16 18 16 12C16 6 12 3 12 3M12 21C12 21 8 18 8 12C8 6 12 3 12 3M3 12C3 7.02944 7.02944 3 12 3" stroke={textColor} strokeWidth="1.5"></path> </g></svg></button>
          <button onClick={toggleFaq}><svg fill={textColor} width="26px" height="26px" viewBox="-2 -2.5 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-messages-f"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M3.656 17.979A1 1 0 0 1 2 17.243V15a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H8.003l-4.347 2.979zM16 10.017a7.136 7.136 0 0 0 0 .369v-.37c.005-.107.006-1.447.004-4.019a3 3 0 0 0-3-2.997H5V2a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2v2.243a1 1 0 0 1-1.656.736L16 13.743v-3.726z"></path></g></svg></button>
          <button onClick={toggleMobileMenu} className='hamburger-menu-btn'>
            <div style={{ border: `1.25px solid ${textColor}`, backgroundColor: `${textColor}` }} />
            <div style={{ border: `1.25px solid ${textColor}`, backgroundColor: `${textColor}` }} />
            <div style={{ border: `1.25px solid ${textColor}`, backgroundColor: `${textColor}` }} />
          </button>
        </div>
        <div className='header-links'>
          <div className='header-links-navs'>
            <Link to='about_us' style={{ color: textColor }}>{t('about_us')}</Link>
            <Link onClick={handleOurServicesClick} to='our_services' style={{ color: textColor }}>{t('ourServices')}</Link>
            <Link to='our_projects' style={{ color: textColor }}>{t('ourProjects')}</Link>
          </div>
          <div>
            <Link to='get_quote' className='header-btn' style={{ color: textColor }}>{t('getQuoteButton')}</Link>
            <Link to='sign_in' className='header-btn' style={{ color: textColor }}>{t('signIn')}</Link>
          </div>
        </div>
      </div>
      <div onClick={closeMobileMenu} className={`mobile-menu-over ${isMobileMenuActive ? 'active' : ''}`}>
        <div className={`mobile-menu ${isMobileMenuActive ? 'active' : ''}`}>
          <div className='mobile-menu-links'>
            <Link onClick={closeMobileMenu} to='about_us' style={{ color: textColor }}>{t('about_us')}</Link>
            <Link onClick={handleOurServicesClick} to='our_services' style={{ color: textColor }}>{t('ourServices')}</Link>
            <Link onClick={closeMobileMenu} to='our_projects' style={{ color: textColor }}>{t('ourProjects')}</Link>
          </div>
          <div className='mobile-menu-buttons'>
            <Link onClick={closeMobileMenu} to='get_quote' className='header-btn' style={{ color: textColor }}>{t('getQuoteButton')}</Link>
            <Link onClick={closeMobileMenu} to='sign_in' className='header-btn' style={{ color: textColor }}>{t('signIn')}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
