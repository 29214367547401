import React, { useState, useRef } from 'react';
import './emailform.scss';
import emailjs from '@emailjs/browser';
import back_img from '../../images/quote/email_form_back_img.png';
import { useTranslation } from 'react-i18next';

function Emailform() {
  const { t, i18n } = useTranslation();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Стан для лоадера
  const [success, setSuccess] = useState(false); // Стан для успішної операції
  const form = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name) {
      newErrors.name = t('NameError');
    }
    if (!formValues.email) {
      newErrors.email = t('EmailError');
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = t('EmailError');
    }
    if (!formValues.message) {
      newErrors.message = t('MessageError');
    }
    return newErrors;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Показуємо лоадер і приховуємо форму
    setLoading(true);

    emailjs
      .sendForm('service_s191r4q', 'template_yrychpr', form.current, 'v45bDMWaxCAOwLzTh')
      .then(
        () => {
          console.log('SUCCESS!');
          setSuccess(true); // Операція успішна
          setTimeout(() => {
            setLoading(false); // Приховуємо лоадер
            setSuccess(false); // Скидаємо стан успіху
          }, 2000); // Затримка 3 секунди
        },
        (error) => {
          console.log('FAILED...', error.text);
          setLoading(false); // У разі невдачі знімаємо лоадер
        }
      );
  };

  return (
    <div id='emailform' style={{ background: `url(${back_img})` }}>
      <div className='emailform-text'>
        <h1><span>{t('quotepStep')} 2</span></h1>
        <h2>{t('quotepStep2H')}</h2>
        <p>{t('quotepStep2P')}</p>
      </div>

      {!loading ? ( // Якщо не лоадер, показуємо форму
        <form ref={form} onSubmit={sendEmail} className='form'>

        <h2>{t('formName')}</h2>
        <input
          name='name'
          placeholder='Jane Dane'
          type="text"
          value={formValues.name}
          onChange={handleInputChange}
          style={{ borderColor: errors.name ? 'red' : '' }}
        />
        {errors.name && <p className="error">{errors.name}</p>}
        <div>
          <div>
            <h2>{t('FormEmail')}</h2>
            <input
              name='email'
              placeholder='jane.doe@gmail.com'
              type="email"
              value={formValues.email}
              onChange={handleInputChange}
              style={{ borderColor: errors.email ? 'red' : '' }}
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div>
            <h2>{t('FormPhone')}</h2>
            <input name='phone' placeholder='(380) 000 00 00' type="tel" />
          </div>
        </div>
        <h2>{t('FormMessage')}</h2>
        <textarea
          name='message'
          value={formValues.message}
          onChange={handleInputChange}
          style={{ borderColor: errors.message ? 'red' : '' }}
        />
        {errors.message && <p className="error">{errors.message}</p>}
        <input className='submit_button' type="submit" value={`${t('FormSend')}   →`} />
      </form>
      ) : ( // Інакше показуємо лоадер
        <div className='loader'>
          <div className='loader-area'>
            <div className={`loader-circle`}></div>
            <div className={`loader-tick ${success ? 'active' : ''}`}></div>
          </div>
          
              <h2 className={`loader-success-header ${success ? 'active' : ''}`}>{t('SuccessH')}</h2>
              <p className={`loader-success-text ${success ? 'active' : ''}`}>{t('SuccessP')}</p>
            
        </div>
      )}
    </div>
  );
}

export default Emailform;
