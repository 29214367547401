// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      metadata: {
        title: "BT Agency - experts in business tourism and corporate event organization. Personalized solutions, VIP service, cost optimization for businesses. Making your travels effective and unforgettable!",
        description: "BT Agency is a business travel and corporate event agency in Ukraine and Europe. We make business trips smooth and productive, offering full-service support: from planning and location selection to 24/7 assistance and risk management. Our approach is tailored to your needs to ensure comfort and cost efficiency.",
        keywords: "Business Tourism, Corporate Travel, Business Trip Organization, Event Management, Incentive Travel, Conferences and Corporate Events, VIP Service for Business, Corporate Tourism in Ukraine, Personalized Corporate Tourism Solutions, Cost Optimization for Business Travel, Business Trips Turnkey Solutions, Business Event Planning and Organization, Risk Management in Business Travel, Inclusion and Accessibility in Corporate Tourism, 24/7 Support and Management, Business Tourism Organization Kyiv, Corporate Tourism Ukraine, Business Travel to Europe from Ukraine, Business Travel Automation, Personalized Services for Business, Creative Approach to Corporate Travel, Exclusive Business Tourism Solutions"
      },
       //header
      "ourServices": "Our Services",
      "about_us": "About Us",
      "newsInsights": "News Insights",
      "ourProjects" : 'Our Projects',
      "contactUs": "Contact Us",
      "getQuoteButton" : "Get Quote",
      "signIn" : "Sign In",
       //indexp
      "titleH1" : "Your Expert B2B4E Travel Management Agency –",
      "titleH2" : "Business Efficiency with Unforgettable Experiences!",
      "titlep1" : "Exceptional corporate travel and events. Wherever you need, however you want, whenever you choose.",
      "titlep2" : "Here, it's all about making business travel easier and unforgettable. We organize worldwide, providing your teams with unique experiences",
      "contactExpert" : "Contact Expert",
      "why" : "Why",
      "choose" : "Choose",
      "us" : "Us",
      "wcuCard1Header" : "Personalization Aligned with Your Business Goals",
      "wcuCard1text" : "Your needs, our solutions. We customize our services to each employee and team to achieve exactly what your company aims for. Your success is our mission.",
      "wcuCard2Header" : "Creative Approach and Innovation",
      "wcuCard2text" : "Business travel is a chance for new discoveries and unforgettable experiences. We bring creativity to every trip, helping you build new connections and inspiration.",
      "wcuCard3Header" : "Luxury and Comfort as Standard",
      "wcuCard3text" : "Luxury is not a privilege — it’s your standard. Our services guarantee the highest level of comfort and care during travels that inspire and motivate.",
      "wcuCard4Header" : "Cost and Resource Optimization",
      "wcuCard4text" : "Efficiency at every step. We find the best ways to optimize your costs without compromising comfort. Your team stays productive before, during, and after the journey.",
      "wcuCard5Header" : "24/7 Support and Management",
      "wcuCard5text" : "We’re always here for you, no matter how far you travel. Your business trips are under our control — anytime, anywhere",
      "wcuCard6Header" : "Inclusion and Accessibility",
      "wcuCard6text" : "Every employee deserves equal opportunities. Our inclusive solutions ensure comfort for all, regardless of special needs. Equality, accessibility, and care.",
      "wcuCard7Header" : "Risk Management",
      "wcuCard7text" : "We anticipate everything. No risk goes unnoticed, as your safety and peace of mind during business trips are our top priority.",
      "getQuoteHeader" : "We develop a strategy and plan for your company’s business events for the year, including budget calculations and full organization. ",
      "getQuoteText" : "We offer both consulting services and complete turnkey solutions. Our goal is to create a solution that perfectly aligns with your business objectives and needs.",
      "getQuoteButton2" : "Get Quote",
      "faqq1" : "How do you customize business travel?",
      "faqa1" : "We analyze your business requirements and personal preferences to create a bespoke travel experience tailored to your corporate group and individual business travelers.",
      "faqh" : "",
      "faqp" : "Your questions answered. Find out everything you need to know about business travel and event management with bt agency.",
      "faqq2" : "Can you manage international travel?",
      "faqa2" : "Absolutely, we have extensive experience in managing international business travel, providing comprehensive support throughout the entire process. ",
      "faqq3" : "What types of corporate events do you organise?",
      "faqa3" : "We provide end-to-end corporate events, including conferences, team-building activities, business meetings, incentive tours, and exclusive retreats for executive teams. ",
      "faqq4" : "What kind of support can I expect?",
      "faqa4" : "Our dedicated support team is available 24/7 to address any issues or changes in your travel or event plans.",
      "MoreBtn" : 'More',
       //footer
      "footerHome" : "Home",
      "footerCompany" : "Company",
      "footerlegal" : "Legal",
      "footerBlog" : "Blog",
      "footerJobs" : "Jobs",
      "footerPrivacy" : "Privacy Policy",
      "footerTerms" : "Terms of use",
      "footerCookies" : "Cookies Policy",
      "footerContact" : "Contact Us",
      "footerAdress" : "Ukraine, Kyiv",
      //aboutus
      "we" : "We –",
      "aboutusheadingh1" : "BT Agency, a travel management agency operating under the Business-to-Business-for-Employees (B2B4E) model",
      "aboutusheadingp1" : "making business trips and events for your employees both efficient and unforgettable.",
      "aboutusheadingp2" : "Our unique approach allows you to focus on what matters most, while we handle all the organization. BT Agency not only takes full control of business trips and events but also helps optimize costs and improve business process efficiency. We create strategic plans that allow you to save time and resources, making your trips and events as productive as possible.",
      "aboutusheadingp3" : "If you're looking for a reliable partner to manage your business travel and events — let’s discuss how we can help!",
      "our" : "Our",
      "values" : "Values",
      "ourvaluesh1" : "Simplicity and Transparency",
      "ourvaluesp1" : "Everything is planned, all details are agreed, and risks are under control. You can focus on what really matters.",
      "ourvaluesh2" : "Connecting Opportunities",
      "ourvaluesp2" : "We create connections that go beyond just travel.",
      "ourvaluesh3" : "Creativity and Innovation",
      "ourvaluesp3" : "We offer unconventional solutions.",
      "ourvaluesh4" : "Personalization and Inclusivity",
      "ourvaluesp4" : "We tailor each trip to individual needs, ensuring comfort and equal opportunities.",
      "ourteamh1" : "Coming Soon",
      "ourteamp1" : "Get to know our talanted team",
      //services
      "services" : 'Services',
      "servicesNavBtn1" : "Travel Management",
      "servicesNavBtn2" : "Event Management",
      "servicesNavBtn3" : "Travel consulting services",
      "servicesNavBtn4" : "Special Assistance",
      "servicesNavBtn5" : "Risk Management",
      "travelManagement" : "Travel Management",
      "travelManagementSubHeading" : "Provide greater value for your employees and your business.",
      "travelManagementp1" : "We know that business trips should be both convenient and strategically aligned with your goals.",
      "travelManagementp2" : "BT Agency provides comprehensive services for organizing business travel, covering all key elements. Cost optimization, efficiency improvement, and ensuring comfort are the areas where we excel, organizing trips that align with your business objectives. We offer:",
      "travelManagementCard1H" : "Corporate Business Travel",
      "travelManagementCard1P" : "We handle every aspect of corporate travel, ensuring a seamless experience from start to finish:",
      "travelManagementCard1Li1" : "Personalized Planning: Tailored itineraries for individual and group trips to meet your specific needs",
      "travelManagementCard1Li2" : "Cost Optimization: We find the most efficient routes and accommodations, maximizing your travel budget without compromising on quality.",
      "travelManagementCard1Li3" : "Luxury and Comfort: Premium services, including first-class flights, exclusive accommodations, and VIP airport services.",
      "travelManagementCard1Li4" : "24/7 Support: Around-the-clock assistance to ensure your team is always supported, no matter where they are in the world.",
      "travelManagementCard2H" : "Wellness and Heritage Tours",
      "travelManagementCard2P" : "Enhance employee well-being while promoting cultural exploration:",
      "travelManagementCard2Li1" : "Wellness Retreats: Recharge your team’s energy and inspire creativity with personalized wellness programs.",
      "travelManagementCard2Li2" : "Cultural Exploration: Discover local heritage and traditions, adding depth to your travel experience",
      "travelManagementCard2Li3" : "Exclusive Locations: Travel to prestigious, serene destinations designed for relaxation and productivity.",
      "travelManagementCard3H" : "International Conferences and Exhibitions",
      "travelManagementCard3P" : "We provide full support for participation in major global events: ",
      "travelManagementCard3Li1" : "End-to-End Logistics: From travel arrangements to accommodation, we handle all the details.",
      "travelManagementCard3Li2" : "On-Site Assistance: Dedicated support teams to ensure a smooth experience at conferences and exhibitions.",
      "travelManagementCard3Li3" : "Networking Opportunities: We create opportunities for your team to connect with key industry players and expand their network.",
      "travelManagementCard4H" : "Exclusive Events and VIP Travel",
      "travelManagementCard4P" : "Ensure that your executives and top clients experience the best: ",
      "travelManagementCard4Li1" : "VIP Access: We organize exclusive access to prestigious events such as Formula 1, Cannes Film Festival, and other highprofile occasions.",
      "travelManagementCard4Li2" : "Custom Experiences: Tailored trips that provide unique and memorable experiences for top-level executives and clients",
      "eventManagement" : "Event Management",
      "eventManagementSubHeading" : "Live, hybrid, and virtual events that inspire, engage, and exceed expectations.",
      "eventManagementp1" : "We know that people perform at their best when they feel motivated and valued.",
      "eventManagementp2" : "Whether you want to impress your audience, create buzz around a new product, or reward your team for their achievements, we know how to organize safe yet impactful events to maximize your return on investment.",
      "eventManagementCard1H" : "Incentive Tours",
      "eventManagementCard1P" : "Incentive tours are a powerful tool to motivate and recognize the achievements of your team, partners, or clients. Our programs include:",
      "eventManagementCard1Li1" : "Personalized Approach: Tailored programs that meet the goals and needs of your company.",
      "eventManagementCard1Li2" : "Exclusive Locations: Travel to prestigious destinations around the world. ",
      "eventManagementCard1Li3" : "Motivational Activities: Engaging participants in activities that inspire and build team values.",
      "eventManagementCard1Li4" : "Recognition of Success: Programs designed to reward top employees or partners.",
      "eventManagementCard1Li5" : "Logistics Management: Full organization of trips, from logistics to local events.",
      "eventManagementCard2H" : "Business Meetings ",
      //eeeeeeee
      "eventManagementCard2P" : "We specialize in organizing business meetings that maximize efficiency and minimize risks: ",
      "eventManagementCard2Li1" : "Online, Offline, and Hybrid Formats: Full technical support for meetings in any format",
      "eventManagementCard2Li2" : "Strategic Planning: Optimizing processes to achieve business goals. ",
      "eventManagementCard2Li3" : "Cost Control: Our Strategic Meetings Management (SMM) ensures transparency and control over meeting expenses.",
      "eventManagementCard2Li4" : "Risk Mitigation: Minimizing risks to ensure seamless event execution.",
      "eventManagementCard3H" : "Events, Team Building, Meetups, Exhibitions",
      "eventManagementCard3P" : "We create events that foster relationships, inspire, and build strong teams:",
      "eventManagementCard3Li1" : "Team Building: Events designed to unite teams through creative and active programs.",
      "eventManagementCard3Li2" : "Meetings and Exhibitions: Organizing participation in industry exhibitions and meetings to enhance your brand’s visibility. ",
      "eventManagementCard3Li3" : "Meetups and Networking: Opportunities to establish new connections and share experiences.",
      "eventManagementCard3Li4" : "Creative Events: Planning innovative events that leave a lasting impression",
      "travelConsultingServices" : "Travel consulting services",
      "travelConsultingServicesSubHeading" : "Empowering your business with strategic insights and tailored solutions.",
      "travelConsultingServicesp1" : "We know that businesses thrive when their travel programs are optimized for efficiency, sustainability, and employee satisfaction.",
      "travelConsultingServicesp2" : "Our consulting services help you build an efficient, balanced travel program that aligns with both your organizational goals and employee well-being. BT Agency provides expert analysis of past trips, identifying key areas for improvement and cost optimization. We develop customized strategies to maximize ROI on future travel while maintaining a focus on sustainability, employee satisfaction, and operational goals. We offer:",
      "travelConsultingServicesCard1H" : "Strategic Planning",
      "travelConsultingServicesCard1P" : "Personalized strategies that align with your business objectives, helping you enhance productivity and control travel-related expenses.",
      "travelConsultingServicesCard2H" : "Customized Solutions",
      "travelConsultingServicesCard2P" : "Our services are fully flexible, with strategies tailored to meet your specific needs, shaped through close collaboration during our consultations.",
      "travelConsultingServicesCard3H" : "Comprehensive Travel Analytics",
      "travelConsultingServicesCard3P" : "A thorough review of your past travel data to uncover inefficiencies and opportunities for cost-saving.",
      "travelConsultingServicesCard4H" : "Sustainability and Well-being",
      "travelConsultingServicesCard4P" : "Our solutions are designed to balance environmental impact, employee satisfaction, and company goals.",
      "travelConsultingServicesCard5H" : "Cutting-edge Technology",
      "travelConsultingServicesCard5P" : "Access to state-of-the-art tools for improved travel management and decision-making processes.",
      "specialAssistance" : "Special Assistance",
      "specialAssistanceSubHeading" : "Empowering your business by ensuring inclusive, accessible travel experiences for every employee.",
      "specialAssistancep1" : "We know that every traveler deserves a seamless experience, where their unique needs are met with care and expertise.",
      "specialAssistancep2" : "We offer inclusive and barrier-free solutions for all travelers, including those with visible and nonvisible disabilities. Our support is tailored to the individual needs of each client, ensuring full accessibility at every stage—from booking to the end of the trip. Our skilled professionals ensure that your journey is comfortable and safe, with a focus on your well-being:",
      "specialAssistanceCard1H" : "Universal Services for All Clients",
      "specialAssistanceCard1P" : "Assistance is available to all customers, regardless of the size or scale of their program.",
      "specialAssistanceCard2H" : "Personalized Support",
      "specialAssistanceCard2P" : "We provide specialized assistance, addressing all needs—from booking airport wheelchair services to ensuring accessible travel destinations and event venues.",
      "specialAssistanceCard3H" : "Adapted Traveler Profiles",
      "specialAssistanceCard3P" : "Our system stores special assistance information, so there is no need to re-enter it each time a booking is made.",
      "specialAssistanceCard4H" : "Coordination and Verification",
      "specialAssistanceCard4P" : "We confirm all accessibility requirements with our partners (hotels, airlines, airports, and transport providers) 48 hours before departure to ensure all needs are met.",
      "specialAssistanceCard5H" : "Dedicated Communication Channels",
      "specialAssistanceCard5P" : "Support is available through dedicated email and phone lines, with future plans to expand to additional communication platforms.",
      "riskManagement" : "Risk Management",
      "riskManagementSubHeading" : "Safety. Reduced Risk. Main Solution.",
      "riskManagementP" : "We understand that every traveler deserves a secure journey tailored to their needs.",
      "riskManagementCard1H" : "24/7 Peace of Mind",
      "riskManagementCard1P" : "We support your dedication to employee safety while minimizing organizational risks. Our approach safeguards both employee security and sensitive information, ensuring legal compliance and proactive safety management.",
      "riskManagementCard2H" : "Focus on Employee Well-Being Elevate Your Care Strategy",
      "riskManagementCard2P" : "Your employees are invaluable. Our risk management services equip you with the resources to: ",
      "riskManagementCard2Li1" : "Quickly assist employees during emergencies. " ,
      "riskManagementCard2Li2" : "Implement proactive measures to enhance safety." ,
      "riskManagementCard3H" : "Safeguarding Data Integrity Dedicated to Data Protection",
      "riskManagementCard3P" : "Ensuring employee safety is critical, but we also prioritize personal data security by: ",
      "riskManagementCard3Li1" : "Preventing unauthorized access while ensuring seamless information flow. " ,
      "riskManagementCard3Li2" : "Regularly updating security policies to align with emerging technologies and legal standards." ,
      "riskManagementCard4H" : "Prepare for the Unexpected Proactive Planning for Secure Travel",
      "riskManagementCard4P" : "We help you and your employees tackle potential challenges by: ",
      "riskManagementCard4Li1" : "Providing essential information for identifying health and safety risks" ,
      //quotep
      "quotepH" : "Let us craft a custom quote ",
      "quotepHSpan" : "for your perfect experience ",
      "quotepStep" : "Step",
      "quotepStep1H" : "Choose your event program",
      "quotepStep1P" : "Select the service that suits your business best — from corporate conferences to team-building retreats. We ensure a personalized approach for each event.",
      "quotepStep2H" : "Fill out the form and send us the details",
      "quotepStep2P" : "Our team will get in touch with you to create a tailored solution for your business event needs",
      "quotepCard1H" : "Corporate Conferences Tailored Corporate Conferences",
      "quotepCard1P" : "We create impactful conferences that boost innovation and business growth",
      "quotepCard2H" : "Team-Building Retreats Unique Team-Building Retreats",
      "quotepCard2P" : "Elevate your team with custom retreats focused on collaboration and leadership.",
      "quotepCard3H" : "Virtual & Hybrid Events Expert Virtual & Hybrid Events",
      "quotepCard3P" : "Engage your audience with seamless virtual and hybrid event solutions. ",
      "quotepCard4H" : "Incentive Travel Programs Exclusive Incentive Travel",
      "quotepCard4P" : "Reward top performers with unforgettable travel experiences. ",
      "formName" : "Full Name",
      "FormEmail" : "Email Address",
      "FormPhone" : "Phone (Optional)",
      "FormMessage" : "Your Message",
      "FormSend" : "Send",
      "NameError" : "Name is required",
      "EmailError" : "Invalid email format",
      "MessageError" : "Message is required",
      "SuccessH" : "Success",
      "SuccessP" : "Thank you, our managers will contact you soon. In the meantime, you can view our works!",
      "SorryH" : 'Coming Soon',
      "sorryP" : 'We’re preparing exciting projects for you ! Very soon, you’ll find videos and materials showcasing our best case studies here. Stay tuned for updates !',
      "BackToHomeBtn" : 'Back to Home',
      "teamCommingSoonH" : 'Coming soon',
      "teamCommingSoonP" : 'Meet our talented team and explore their innovative projects',
      "blogHeadinH" : 'Innovations and Trends in Business Travel and Events',
"blogHeadinP" : 'Stay connected with the latest insights from BT Agency – from expert tips to unique case studies that will make your business trip unforgettable.',
"gotoblogBtn" : 'Go to Blog',
"CookieAlertH" : "We use cookies to improve your experience on our website.",
"CookieAlertP" : "By continuing to use our website, you agree to our Privacy Policy and Cookie Policy. You can adjust your preferences  or learn more about how  we use cookies on our site.",
"CookieAlertAcceptBtn" : "Accept All Cookies ",
"CookieAlertDenyBtn" : "Deny",
"CookieAlertLearnMore" : "Learn More",
//cookiesp
"CookiesPageH" : "Cookie Policy " ,
"CookiesPageP1" : "This Cookie Policy explains " ,
"CookiesPageP2" : " how uses cookies on our website." ,
"CookiesPageEl1H" : "What Are Cookies ?" ,
"CookiesPageEl1P1" : "Cookies are small text files that are stored on your device when you visit our website. They help us remember your preferences, ensure security and collect analytical data." ,
"CookiesPageEl2H" : "What Types of Cookies Do We Use ?" ,
"CookiesPageEl2P1" : "Ensure basic functionality of the website." ,
"CookiesPageEl2P2" : "Help us understand how users interact with the site." ,
"CookiesPageEl2P3" : "Used to display personalized advertising." ,
"CookiesPageEl2P1S" : "Necessary Cookies :" ,
"CookiesPageEl2P2S" : "Analytical Cookies :" ,
"CookiesPageEl2P3S" : "Marketing Cookies :" ,
"CookiesPageEl3H" : "How to Manage Cookies ?" ,
"CookiesPageEl3P1" : "You can adjust your cookies settings or refuse their use through your browser settings. Note that disabling some cookies may affect the functionality of the website." ,
"CookiesPageEl4H" : "Policy Changes" ,
"CookiesPageEl4P1" : "We reserve the right to update this Cookie Policy as needed. Updates will be reflected on this page." ,
"PrivacyPolicyH" : "Privacy Policy",
"PrivacyPolicyP1" : "This Privacy Policy describes how ",
"PrivacyPolicyP2" : " ( hereinafter “We” , “Agency” ) collects, uses, and protects your personal data while using our website and services. ",
"PrivacyPolicyElH" : "What Data Do We Collect ? ",
"PrivacyPolicyElP1" : "Name, surname, email, phone number, company and other data you voluntarily provide via forms on the website",
"PrivacyPolicyElP2" : "Automatically collected data : IP address, browser information, cokkies and other technical data related to your device. ",
"PrivacyPolicyElH" : "How Do We Use Your Data ?",
"PrivacyPolicyElP1" : "To provide and improve our services. ",
"PrivacyPolicyElP2" : "To respond to your inquiries and communicate with you ",
"PrivacyPolicyElP3" : "For marketing and analytical purposes ( with your consent )",
"PrivacyPolicyElH" : "Your Rights",
"PrivacyPolicyElP1" : "You have the rights to access your personal data, correct, delete it and withdraw your consent for its processing. ",
"PrivacyPolicyElP2" : "To exercise these rights, please contact us at ",
"PrivacyPolicyElH" : "Policy Changes",
"PrivacyPolicyElP1" : "We may update this Privacy Policy. Any changes will be posted on this page."

    }
  },
  
  ua: {
    translation: {
      metadata: {
        title: "ВТ Agency - експерт у бізнес-туризмі та організації корпоративних заходів. Індивідуальні рішення, ѴІР-сервіс, оптимізація витрат для бізнесу. Робимо ваші подорожі ефективними та незабутніми!",
        description: "ВТ Agency - агентство з бізнес-туризму та організації корпоративних заходів в Україні та Європі. Ми допомагаємо зробити ділові подорожі зручними та результативними, пропонуючи послуги з повним циклом: від планування і підбору місць до підтримки 24/7 і управління ризиками. Наш підхід адаптується під ваші потреби, щоб забезпечити комфорт і оптимізувати витрати.",
        keywords: "Бізнес-туризм, Корпоративні подорожі, Організація ділових поїздок, Управління подіями, Інсентив-тури, Конференції та корпоративні заходи, VIP-сервіс для бізнесу, Корпоративний туризм в Україні, Індивідуальні рішення для корпоративного туризму, Оптимізація витрат на бізнес-подорожі, Бізнес-подорожі під ключ, Планування та організація бізнес-заходів, Управління ризиками у ділових поїздках, Інклюзивність та доступність у корпоративному туризмі, Підтримка та управління 24/7, Організація бізнес-туризму Київ, Корпоративний туризм Україна, Ділові подорожі в Європу з України, Автоматизація бізнес-туризму, Персоналізація послуг для бізнесу, Креативний підхід до корпоративних подорожей, Ексклюзивні рішення для бізнес-туризму"
      },
      //header
      "ourServices": "Послуги",
      "about_us": "Про нас",
      "newsInsights": "Новини та інсайти",
      "contactUs": "Контакти",
      "ourProjects" : 'Наші проєкти',
      "getQuoteButton" : "Завмовити",
      "signIn" : "Увійти",
      //indexp
      "titleH1" : "Ваше експертне агентство з управління B2B4E подорожами –",
      "titleH2" : "Бізнес-ефективність з незабутніми враженнями!",
      "titlep1" : "Виняткові корпоративні подорожі та події. Де завгодно, як завгодно, коли завгодно",
      "titlep2" : "У нас все про те, щоб зробити бізнес-подорожі легшими та незабутніми. Ми організовуємо по всьому світу, надаючи вашим командам унікальні враження.",
      "contactExpert" : "Зв'язатись з експертом",
      "why" : "Чому",
      "choose" : "Обирають",
      "us" : "Нас",
      "wcuCard1Header" : "Персоналізація відповідно до ваших бізнес-цілей",
      "wcuCard1text" : "Ваші потреби — наші рішення. Ми адаптуємо наші послуги під кожного працівника і кожну групу, щоб досягти саме тих результатів, яких прагне ваша компанія. Ваш успіх — наша місія.",
      "wcuCard2Header" : "Креативний підхід та інновації",
      "wcuCard2text" : "Бізнес-подорож — це можливість для нових відкриттів та вражень. Ми робимо кожну поїздку унікальною, додаючи креативні елементи, що допомагають створювати нові зв’язки та натхнення.",
      "wcuCard3Header" : "Люкс та комфорт як стандарт",
      "wcuCard3text" : "Розкіш не повинна бути привілеєм — це ваш стандарт. Наші послуги гарантують найвищий рівень комфорту та турботи під час подорожей, які надихають та мотивують.",
      "wcuCard4Header" : "Оптимізація витрат і ресурсів",
      "wcuCard4text" : "Ефективність на кожному етапі. Ми знаходимо найкращі рішення для оптимізації ваших витрат без шкоди для комфорту. Ваша команда — продуктивна до, під час і після подорожі.",
      "wcuCard5Header" : "Підтримка та управління 24/7",
      "wcuCard5text" : "Ми завжди на зв'язку, як би далеко ви не були. Ваші бізнес-подорожі під нашим контролем — у будь-який час, у будь-якій точці світу.",
      "wcuCard6Header" : "Інклюзивність та доступність",
      "wcuCard6text" : "Кожен співробітник має рівні можливості. Наші інклюзивні рішення забезпечують комфорт для всіх, незалежно від особливих потреб. Рівність, доступність, турбота.",
      "wcuCard7Header" : "Управління ризиками",
      "wcuCard7text" : "Ми передбачаємо все. Жоден ризик не залишиться поза увагою, адже ваша безпека та спокій під час бізнес-подорожей — наш пріоритет.",
      "getQuoteHeader" : "Ми розробляємо стратегію та план бізнес-заходів для вашої компанії на рік, включаючи прорахунок бюджету та повну організацію. ",
      "getQuoteText" : "Пропонуємо як консультаційні послуги, так і комплексну реалізацію під ключ. Створимо рішення, яке ідеально відповідатиме вашим бізнес-цілям та потребам.",
      "getQuoteButton2" : "Отримати пропозицію",
      "faqh" : "",
      "faqp" : "Відповіді на ваші запитання тут. Дізнайтеся все, що вам потрібно знати про ділові поїздки та організацію подій разом з BT Agency.",
      "faqq1" : "Як ви персоналізуєте бізнес-подорожі?",
      "faqa1" : "Ми аналізуємо ваші бізнес-вимоги та особисті уподобання для створення індивідуального досвіду подорожей, який адаптований як для вашої корпоративної групи, так і для кожного бізнес-клієнта окремо.",
      "faqq2" : "Чи можете ви організувати міжнародні подорожі?",
      "faqa2" : "Безперечно, ми маємо великий досвід у сфері організації міжнародних бізнес-подорожей, забезпечуючи комплексну підтримку на всіх етапах процесу.",
      "faqq3" : "Які види корпоративних заходів ви організовуєте?",
      "faqa3" : "Ми пропонуємо наскрізне планування з організації корпоративних заходів, включаючи конференції, тімбілдинги, ділові зустрічі, інсентив-тури та ексклюзивні корпоративні виїзні ретрити для керівних команд.",
      "faqq4" : "Яку підтримку я можу очікувати?",
      "faqa4" : "Наша команда підтримки доступна цілодобово, щоб вирішити будь-які питання або внести зміни до ваших планів подорожей або заходів.",
      "MoreBtn" : 'Більше',
      //footer
      "footerHome" : "Головна",
      "footerCompany" : "Компанія",
      "footerlegal" : "Правова інформація",
      "footerBlog" : "Блог",
      "footerJobs" : "Роботи",
      "footerPrivacy" : "Політика конфіденційності",
      "footerTerms" : "Умови використання",
      "footerCookies" : "Політика файлів cookie",
      "footerContact" : "Контакти",
      "footerAdress" : "Україна, Київ",
       //aboutus
      "we" : "Ми –",
      "aboutusheadingh1" : "BT Агентство з управління бізнес-подорожами за моделлю Business-to-Business-for-Employees (B2B4E)",
      "aboutusheadingp1" : "яке робить бізнес-подорожі та події для ваших співробітників ефективними та незабутніми.",
      "aboutusheadingp2" : "Наш унікальний підхід дозволяє вам зосередитися на головному, а ми беремо на себе всю організацію. BT Agency не лише забезпечує повний контроль над бізнес-поїздками та подіями, але й допомагає оптимізувати витрати та підвищити ефективність бізнес-процесів. Ми створюємо стратегічні плани, які дозволяють зекономити час і ресурси, зробивши ваші подорожі і події максимально продуктивними.",
      "aboutusheadingp3" : "Якщо ви шукаєте надійного партнера для управління діловими подорожами та подіями — давайте обговоримо, як ми можемо вам допомогти!",
      "our" : "Наші",
      "values" : "Цінності",
      "ourvaluesh1" : "Простота і прозорість",
      "ourvaluesp1" : "Усе сплановано, усі деталі узгоджені, ризики під контролем. Ви можете зосередитися на головному.",
      "ourvaluesh2" : "З'єднання можливостей",
      "ourvaluesp2" : "Ми створюємо зв'язки, що виходять за межі подорожей.",
      "ourvaluesh3" : "Креативність та інноваційність",
      "ourvaluesp3" : "Ми пропонуємо нестандартні рішення.",
      "ourvaluesh4" : "Персоналізація та інклюзивність",
      "ourvaluesp4" : "Ми адаптуємо кожну поїздку під індивідуальні потреби кожного, забезпечуючи комфорт і рівні можливості.",
      "ourteamh1" : "Незабаром",
      "ourteamp1" : "Познайомтесь з нашою талановитою командою",
      //services
      "services" : 'Сервіси',
      "servicesNavBtn1" : "Управління подорожами",
      "servicesNavBtn2" : "Управління подіями",
      "servicesNavBtn3" : "Послуги з консалтингу",
      "servicesNavBtn4" : "Інклюзивність",
      "servicesNavBtn5" : "Управління ризиками",
      "travelManagement" : "Управління подорожами",
      "travelManagementSubHeading" : "Забезпечте більшу цінність для ваших співробітників та вашого бізнесу.",
      "travelManagementp1" : "Ми знаємо, що ділові поїздки повинні бути зручними та стратегічно узгодженими з вашими цілями.",
      "travelManagementp2" : "Агентство BT пропонує комплексні послуги з організації ділових подорожей, охоплюючи всі ключові елементи. Оптимізація витрат, підвищення ефективності та забезпечення комфорту — це сфери, в яких ми виділяємося, організовуючи поїздки, що відповідають вашим бізнес-цілям. Ми пропонуємо:",
      "travelManagementCard1H" : "Корпоративні ділові поїздки",
      "travelManagementCard1P" : "Ми беремо на себе всі аспекти корпоративних подорожей, забезпечуючи безперебійну роботу з початку до кінця: ",
      "travelManagementCard1Li1" : "Персоналізоване планування: Адаптовані маршрути для індивідуальних і групових поїздок, щоб задовольнити ваші специфічні потреби. ",
      "travelManagementCard1Li2" : "Оптимізація витрат: Ми знаходимо найефективніші маршрути та проживання, максимізуючи ваш бюджет на подорожі без компромісів у якості. ",
      "travelManagementCard1Li3" : "Розкіш і комфорт: Преміум-послуги, включаючи авіаперельоти бізнес-класом, ексклюзивне проживання та VIP-послуги в аеропортах. ",
      "travelManagementCard1Li4" : "24/7 підтримка: Круглодобова допомога, щоб ваша команда завжди отримувала підтримку, де б вона не знаходилася в світі.",
      "travelManagementCard2H" : "Тури здоров'я та культурної спадщини",
      "travelManagementCard2P" : "Покращуйте добробут співробітників, сприяючи культурному дослідженню:",
      "travelManagementCard2Li1" : "Відпочинок для здоров'я: Відновлюйте енергію вашої команди та надихайте творчість за допомогою персоналізованих програм здоров'я.",
      "travelManagementCard2Li2" : "Культурне дослідження: Відкривайте місцеву спадщину та традиції, надаючи глибину вашому досвіду подорожі.",
      "travelManagementCard2Li3" : "Ексклюзивні локації: Подорожуйте до престижних, спокійних місць, розроблених для відпочинку та продуктивності.",
      "travelManagementCard3H" : "Міжнародні конференції та виставки",
      "travelManagementCard3P" : "Ми надаємо повну підтримку участі у великих глобальних подіях:",
      "travelManagementCard3Li1" : "Логістика 'під ключ': Від організації подорожей до розміщення — ми беремо на себе всі деталі.",
      "travelManagementCard3Li2" : "Підтримка на місці: Призначені команди підтримки для забезпечення безперебійної роботи на конференціях та виставках.",
      "travelManagementCard3Li3" : "Можливості для нетворкінгу: Ми створюємо можливості для вашої команди для встановлення зв’язків з ключовими гравцями в індустрії та розширення їхньої мережі. ",
      "travelManagementCard4H" : "Ексклюзивні події та VIP-подорожі",
      "travelManagementCard4P" : "Забезпечте, щоб ваші керівники та найкращі клієнти отримали найкращі враження:",
      "travelManagementCard4Li1" : "VIP-доступ: Ми організовуємо ексклюзивний доступ до престижних подій, таких як Формула-1, Каннський кінофестиваль та інші високопрофільні події.",
      "travelManagementCard4Li2" : "Індивідуальні враження: Адаптовані поїздки, які забезпечують унікальні та незабутні враження для топ-менеджерів та клієнтів.",
      "eventManagement" : "Управління подіями",
      "eventManagementSubHeading" : "Живі, гібридні та віртуальні події, які надихають, залучають і перевершують очікування.",
      "eventManagementp1" : "Ми знаємо, що люди демонструють найкращі результати, коли відчувають мотивацію та цінність.",
      "eventManagementp2" : "Незалежно від того, чи хочете ви вразити свою аудиторію, створити ажіотаж навколо нового продукту чи винагородити свою команду за досягнення, ми знаємо, як організувати безпечні, але вражаючі події, щоб максимізувати ваші інвестиції.",
      "eventManagementCard1H" : "Інсентив тури",
      "eventManagementCard1P" : "Інсентив тури — це потужний інструмент для мотивації та визнання досягнень вашої команди, партнерів чи клієнтів. Наші програми включають:",
      "eventManagementCard1Li1" : "Персоналізований підхід: Адаптовані програми, що відповідають цілям і потребам вашої компанії. ",
      "eventManagementCard1Li2" : "Ексклюзивні локації: Подорожі до престижних напрямків по всьому світу. ",
      "eventManagementCard1Li3" : "Мотиваційні активності: Залучення учасників у діяльність, яка надихає та формує командні цінності. ",
      "eventManagementCard1Li4" : "Визнання успіху: Програми, спрямовані на винагороду найкращих співробітників або партнерів. ",
      "eventManagementCard1Li5" : "Управління логістикою: Повна організація поїздок, від логістики до місцевих подій.",
      "eventManagementCard2H" : "Управління бізнес-зустрічами",

      /////
      "eventManagementCard2P" : "Ми спеціалізуємося на організації бізнес-зустрічей, що максимізують ефективність і мінімізують ризики: ",
      "eventManagementCard2Li1" : "Онлайн, офлайн та гібридні формати: Повна технічна підтримка зустрічей у будь-якому форматі. ",
      "eventManagementCard2Li2" : "Стратегічне планування: Оптимізація процесів для досягнення бізнес-цілей. ",
      "eventManagementCard2Li3" : "Контроль витрат: Наша стратегія управління зустрічами (SMM) забезпечує прозорість і контроль над витратами на зустрічі. ",
      "eventManagementCard2Li4" : "Зменшення ризиків: Мінімізація ризиків для забезпечення безперебійного виконання подій.",
      "eventManagementCard3H" : "Події, тімбілдинг, зустрічі, виставки",
      "eventManagementCard3P" : "Ми створюємо події, які сприяють розвитку відносин, надихають і формують сильні команди:",
      "eventManagementCard3Li1" : "Тімбілдинг: Події, що об'єднують команди через творчі та активні програми.",
      "eventManagementCard3Li2" : "Зустрічі та виставки: Організація участі у галузевих виставках та зустрічах для підвищення впізнаваності вашого бренду.",
      "eventManagementCard3Li3" : "Зустрічі та нетворкінг: Можливості для встановлення нових зв'язків та обміну досвідом.",
      "eventManagementCard3Li4" : "Креативні події: Планування інноваційних заходів, що залишають тривале враження.",
      "travelConsultingServices" : "Послуги з консалтингу в сфері подорожей",
      "travelConsultingServicesSubHeading" : "Підтримка вашого бізнесу за допомогою стратегічних інсайтів і індивідуальних рішень.",
      "travelConsultingServicesp1" : "Ми знаємо, що бізнес процвітає, коли їхні програми подорожей оптимізовані для ефективності, стійкості та задоволеності співробітників.",
      "travelConsultingServicesp2" : "Наші консалтингові послуги допомагають вам створити ефективну, збалансовану програму подорожей, яка відповідає як вашим організаційним цілям, так і благополуччю співробітників. Агенція BT надає експертний аналіз минулих подорожей, визначаючи ключові області для вдосконалення та оптимізації витрат. Ми розробляємо індивідуальні стратегії для максимізації ROI від майбутніх поїздок, зосереджуючи увагу на стійкості, задоволеності співробітників і операційних цілях. Ми пропонуємо:",
      "travelConsultingServicesCard1H" : "Стратегічне планування",
      "travelConsultingServicesCard1P" : "Персоналізовані стратегії, які відповідають вашим бізнес-цілям, що допомагає підвищити продуктивність і контролювати витрати, пов'язані з подорожами.",
      "travelConsultingServicesCard2H" : "Індивідуальні рішення",
      "travelConsultingServicesCard2P" : "Наші послуги повністю гнучкі, зі стратегіями, адаптованими до ваших специфічних потреб, сформованими через тісну співпрацю під час наших консультацій.",
      "travelConsultingServicesCard3H" : "Комплексна аналітика подорожей",
      "travelConsultingServicesCard3P" : "Глибокий аналіз ваших минулих даних про подорожі для виявлення неефективності та можливостей для економії витрат.",
      "travelConsultingServicesCard4H" : "Стійкість і благополуччя",
      "travelConsultingServicesCard4P" : "Наші рішення розроблені так, щоб забезпечити баланс між екологічним впливом, задоволеністю співробітників і корпоративними цілями.",
      "travelConsultingServicesCard5H" : "Сучасні технології",
      "travelConsultingServicesCard5P" : "Доступ до передових інструментів для покращення управління подорожами та процесів прийняття рішень.",
      "specialAssistance" : "Інклюзивність",
      "specialAssistanceSubHeading" : "Підтримка вашого бізнесу шляхом забезпечення інклюзивного та доступного досвіду подорожей для кожного співробітника.",
      "specialAssistancep1" : "Ми знаємо, що кожен мандрівник заслуговує на безперешкодний досвід, де його унікальні потреби задовольняються з увагою та професіоналізмом.",
      "specialAssistancep2" : "Ми пропонуємо інклюзивні та безперешкодні рішення для всіх мандрівників, включаючи осіб з видимими та невидимими інвалідностями. Наша підтримка адаптована до індивідуальних потреб кожного клієнта, що забезпечує повну доступність на всіх етапах — від бронювання до завершення поїздки. Наші кваліфіковані спеціалісти гарантують, що ваша подорож буде комфортною і безпечною, з акцентом на ваше благополуччя:",
      "specialAssistanceCard1H" : "Універсальні послуги для всіх клієнтів",
      "specialAssistanceCard1P" : "Допомога доступна для всіх замовників, незалежно від розміру або масштабів їхньої програми.",
      "specialAssistanceCard2H" : "Персоналізована підтримка",
      "specialAssistanceCard2P" : "Ми надаємо спеціалізовану допомогу, яка охоплює всі потреби — від бронювання послуг в аеропортах (інвалідні коляски) до забезпечення доступних туристичних напрямків та місць проведення заходів.",
      "specialAssistanceCard3H" : "Адаптовані профілі мандрівників",
      "specialAssistanceCard3P" : "Наша система зберігає інформацію про спеціальну допомогу, тож немає потреби повторно вводити її кожного разу при бронюванні.",
      "specialAssistanceCard4H" : "Координація та перевірка",
      "specialAssistanceCard4P" : "Ми підтверджуємо всі вимоги щодо доступності з нашими партнерами (готелями, авіакомпаніями, аеропортами та постачальниками транспортних послуг) за 48 годин до відправлення, щоб забезпечити задоволення всіх потреб.",
      "specialAssistanceCard5H" : "Присвячені канали зв'язку",
      "specialAssistanceCard5P" : "Підтримка доступна через спеціалізовані електронну пошту та телефонні лінії, з планами на розширення до додаткових комунікаційних платформ у майбутньому.",
      "riskManagement" : "Управління ризиками",
      "riskManagementSubHeading" : "Безпека. Зменшений ризик. Основне рішення.",
      "riskManagementP" : "Ми розуміємо, що кожен мандрівник заслуговує на безпечну подорож, адаптовану до його потреб.",
      "riskManagementCard1H" : "Цілодобовий спокій",
      "riskManagementCard1P" : "Ми підтримуємо вашу відданість безпеці співробітників, мінімізуючи організаційні ризики. Наш підхід забезпечує захист як безпеки працівників, так і чутливої інформації, гарантуючи відповідність юридичним вимогам та проактивне управління безпекою.",
      "riskManagementCard2H" : "Зосередження на добробуті співробітників. Підвищте свою стратегію догляду",
      "riskManagementCard2P" : "Ваші співробітники є безцінними. Наші послуги з управління ризиками надають вам ресурси для: ",
      "riskManagementCard2Li1" : "Швидкої допомоги співробітникам під час надзвичайних ситуацій. " ,
      "riskManagementCard2Li2" : "Впровадження проактивних заходів для підвищення безпеки." ,
      "riskManagementCard3H" : "Забезпечення цілісності даних. Віддані захисту даних.",
      "riskManagementCard3P" : "Забезпечення безпеки співробітників є критично важливим, але ми також пріоритетно ставимося до захисту особистих даних:",
      "riskManagementCard3Li1" : "Запобігання несанкціонованому доступу при забезпеченні безперебійного потоку інформації. " ,
      "riskManagementCard3Li2" : "Регулярне оновлення політики безпеки, щоб відповідати новим технологіям та юридичним стандартам." ,
      "riskManagementCard4H" : "Готуйтеся до непередбачуваного. Проактивне планування для безпечних подорожей.",
      "riskManagementCard4P" : "Ми допомагаємо вам і вашим співробітникам справлятися з потенційними викликами, надаючи:",
      "riskManagementCard4Li1" : "Необхідну інформацію для виявлення ризиків для здоров'я та безпеки" ,
      //quotep
      "quotepH" : "Створімо індивідуальну пропозицію ",
      "quotepHSpan" : "для вашого ідеального досвіду",
      "quotepStep" : "Крок",
      "quotepStep1H" : "Оберіть програму для вашого бізнес-заходу",
      "quotepStep1P" : "Виберіть рішення, яке найкраще відповідає потребам вашої компанії — від корпоративних конференцій до стратегічних тімбілдинг-ретритів. Ми гарантуємо індивідуальний підхід до кожного корпоративного заходу",
      "quotepStep2H" : " Заповніть форму та надайте деталі",
      "quotepStep2P" : "Наша команда зв'яжеться з вами, щоб запропонувати індивідуальне рішення для ваших бізнес-заходів або корпоративних подорожей ",
      "quotepCard1H" : "Корпоративні конференції Індивідуальні корпоративні конференції",
      "quotepCard1P" : "Ми організовуємо впливові конференції, що стимулюють інновації та сприяють розвитку бізнесу",
      "quotepCard2H" : "Тімбілдинг-ретрити Унікальні тімбілдинг-ретрити",
      "quotepCard2P" : "Підніміть ефективність вашої команди за допомогою індивідуальних ретритів, спрямованих на співпрацю та розвиток лідерських якостей",
      "quotepCard3H" : "Віртуальні та гібридні заходи. Експертні віртуальні та гібридні заходи",
      "quotepCard3P" : "Залучайте вашу аудиторію за допомогою бездоганних рішень для віртуальних та гібридних заходів",
      "quotepCard4H" : "Інсентив-програми Ексклюзивні інсентив-програми",
      "quotepCard4P" : "Винагороджуйте найкращих співробітників незабутніми подорожами. ",
      "formName" : "ПІБ",
      "FormEmail" : "Електронна пошта",
      "FormPhone" : "Номер телефону",
      "FormMessage" : "Ваше повідомлення",
      "FormSend" : "Надіслати",
      "NameError" : "Вкажіть Ім'я",
      "EmailError" : "Вкажіть електронну пошту",
      "MessageError" : "Напишіть повідомлення",
      "SuccessH" : "Успішно",
      "SuccessP" : "Дякуємо, наші менеджери зв'яжуться з вами найближчим часом. А поки можете переглянути наші роботи!",
      "SorryH" : 'Незабаром',
      "sorryP" : 'Готуємо для вас захоплюючі проекти ! Зовсім скоро тут будуть відео та матеріали з нашими найкращими кейсами. Слідкуйте за оновленнями !',
      "BackToHomeBtn" : 'На Головну',
      "teamCommingSoonH" : 'Незабаром',
      "teamCommingSoonP" : 'познайомтеся з нашою талановитою командою та ознайомтеся з їхніми інноваційними',
      "blogHeadinH" : 'Інновації та тренди у ділових подорожах та подіях',
"blogHeadinP" : "Залишайтесь на зв'язку з останніми інсайтами від BT Agency – від експертних порад до унікальних кейсів, які допоможуть зробити вашу бізнес-подорож незабутньою.",
"gotoblogBtn" : 'Блог',
"CookieAlertH" : "Ми використовуємо файли cookie для покращення вашого досвіду на нашому сайті.",
"CookieAlertP" : "Продовжуючи користування сайтом, ви погоджуєтесь із нашою Політикою конфіденційності та Політикою файлів cookie. Ви можете налаштувати свої вподобання або дізнатися більше про використання файлів cookie на нашому сайті.",
"CookieAlertAcceptBtn" : "Прийняти всі файли cookie",
"CookieAlertDenyBtn" : "Відхилити",
"CookieAlertLearnMore" : "Докладніше",
//cookiesp
"CookiesPageH" : "Політика файлів Cookie" ,
"CookiesPageP1" : "Ця політика файлів cookie пояснює, як " ,
"CookiesPageP2" : " використовує файли cookie на нашому сайті." ,
"CookiesPageEl1H" : "Що таке файли cookie ?" ,
"CookiesPageEl1P1" : "Файли Cookie - це невеликі текстові файли, які зберігаються на вашому пристрої, коли ви відвідуєте наш сайт. Вони допомагають нам запам’ятовувати ваші налаштування, забезпечувати безпеку та збирати аналітичні дані." ,
"CookiesPageEl2H" : "Які типи файлів cookie ми використовуємо ?" ,
"CookiesPageEl2P1" : "Забезпечують базову функціональність сайту." ,
"CookiesPageEl2P2" : "Допомагають нам зрозуміти, як користувачі взаємодіють із сайтом. " ,
"CookiesPageEl2P3" : "Використовуються для показу персоналізованої реклами." ,
"CookiesPageEl2P1S" : "Необхідні дані cookie :" ,
"CookiesPageEl2P2S" : "Аналітичні файли cookie :" ,
"CookiesPageEl2P3S" : "Маркетингові файли cookie :" ,
"CookiesPageEl3H" : "Як керувати файлами Cookie ?" ,
"CookiesPageEl3P1" : "Ви можете змінити налаштування файлів cookie або відмовитися від їх використання через налаштування вашого браузера. Зверніть увагу, що відмова від деяких файлів cookie може вплинути на функціональність сайту. " ,
"CookiesPageEl4H" : "Зміни до політики" ,
"CookiesPageEl4P1" : "Ми залишаємо за собою право змінювати цю Політику файлів cookie у разі потреби. Оновлення будуть відображені на цій сторінці." ,

"PrivacyPolicyH" : "Політика Конфіденційності",
"PrivacyPolicyP1" : "Ця політика конфіденційності описує, як ",
"PrivacyPolicyP2" : " ( далі “Ми”, “Агентство” ) збирає, використовує та захищає ваші персональні дані під час використання нашого сайту та послуг.",
"PrivacyPolicyEl1H" : "Які дані ми збираємо ?",
"PrivacyPolicyEl1P1" : "Ім’я, прізвище, електронну пошту, номер телефону, компанію, а також інші дані, які ви добровільно надаєте через форми на сайті. ",
"PrivacyPolicyEl1P2" : "Автоматично зібрані дані: IP-адреса, інформація про браузер, файли cookie та інші технічні дані, що стосуються вашого пристрою.",
"PrivacyPolicyEl2H" : "Як ми використовуємо ваші дані ? ",
"PrivacyPolicyEl2P1" : "Для надання та покращення наших послуг.",
"PrivacyPolicyEl2P2" : "Для відповіді на ваші запити та зв’язку з вами.",
"PrivacyPolicyEl2P3" : "Для маркетингових і аналітичних цілей (за вашою згодою).",
"PrivacyPolicyEl3H" : "Ваші права",
"PrivacyPolicyEl3P1" : "Ви маєте право на доступ до ваших персональних даних, їх виправлення, видалення, а також на відкликання згоди на їх обробку.",
"PrivacyPolicyEl3P2" : "Щоб скористатися цими правами, зв’яжіться з нами за адресою ",
"PrivacyPolicyEl4H" : "Зміни до політики",
"PrivacyPolicyEl4P1" : "Ми можемо оновлювати цю Політику конфіденційності. Будь-які зміни будуть опубліковані на цій сторінці."

    }
  }
};  

i18n.use(initReactI18next).init({
  resources,
  lng: 'ua', // Default language
  fallbackLng: 'ua',
  interpolation: {
    escapeValue: false,
  }
});

export default i18n;
