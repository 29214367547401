import React from 'react'
import './events.scss'
import GridComponent from '../../components/gridcomp/GridComponent'
const items1 = [
    { title: "Life sciences and pharma travel", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_FeDFVjajJbqVHRzf/hihczyyrvj263sbv.png?w=300&dpr=2", imageHeight: "770px" },
    { title: "IT, Gaming, Gambling", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_FeDFVjajJbqVHRzf/hihczyyrvj263sbv.png?w=300&dpr=2", imageHeight: "770px" },
  ];
  const items2 = [
    { title: "Media, entertainment and sports travel", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg", imageHeight: "430px" },
    { title: "Energy, resources and marine travel", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg", imageHeight: "190px" },
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg", imageHeight: "430px" }
  ];
  const items3 = [  
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg", imageHeight: "450px" },
    { title: "Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg", imageHeight: "690px" },
  ];
  const items4 = [
    { title: "Live events", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg", imageHeight: "170px" },
    { title: "Travel and expense strategy", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg", imageHeight: "150px" },
    { title: "Travel and expense strategy", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://www.parkregisbirmingham.co.uk/wp-content/uploads/2016/10/business-meeting-birmingham.jpg", imageHeight: "160px" },
  ];
  const items5 = [
    { title: "Travel Data Analytics", text: "Discover trends in your travel and expense data with BT Agency. Utilizing AI-driven technology and expert analysis, we consolidate data from various sources, perform advanced analytics, and use predictive modeling to drive meaningful change.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/xe17pa5vho1kvr6x.svg?w=300&dpr=2", imageHeight: "370px" },
    { title: "Travel services outsourcing", text: "Adapt quickly to changing business needs with BT Agency's expert travel services. Whether for long-term projects or short-term needs, we provide the resources and expertise to enhance your travel program precisely where it matters most.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/xe17pa5vho1kvr6x.svg?w=300&dpr=2", imageHeight: "370px" },
  ];
  const items6 = [
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/xe17pa5vho1kvr6x.svg?w=300&dpr=2", imageHeight: "355px" },
    { title: "Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/xe17pa5vho1kvr6x.svg?w=300&dpr=2", imageHeight: "150px" },

  ];
  const items7 = [
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/5qgn01e4fm6uvvi6.svg?w=400&dpr=2", imageHeight: "170px" },
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/5qgn01e4fm6uvvi6.svg?w=400&dpr=2", imageHeight: "150px" },
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/5qgn01e4fm6uvvi6.svg?w=400&dpr=2", imageHeight: "150px" }
  ];
  const items8 = [
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/5qgn01e4fm6uvvi6.svg?w=400&dpr=2", imageHeight: "170px" },
    { title: "Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/jvj1zjynvz3os0i5.svg?w=600&dpr=2", imageHeight: "150px" },

  ];
  const items9 = [
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/5qgn01e4fm6uvvi6.svg?w=400&dpr=2", imageHeight: "170px" },
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/5qgn01e4fm6uvvi6.svg?w=400&dpr=2", imageHeight: "150px" },
    { title: "Some Title", text: "This is a beautiful card. Something cool you can do with your product can be described here.", image: "https://files.umso.co/lib_SUQilvCDrDwywovw/5qgn01e4fm6uvvi6.svg?w=400&dpr=2", imageHeight: "150px" }
  ];

function Events() {
  return (

    <div id='events'>
      <div>
        <h1>Travel Consulting</h1>
        <h3>Provide greater value for your employees and your business.</h3>
        <h4>Our unique travel solutions.</h4>
        <GridComponent height={'930px'} items={items1} columns={2} layoutType="two-medium"/>
        <GridComponent height={'645px'} items={items2} columns={3}/>
      </div>
      <div>
        <h1>Event Management</h1>
        <h3>EXCITE. ENGAGE. EXCEED.</h3>
        <GridComponent height={'850px'} items={items3} columns={2} layoutType="two-medium"/>
        <GridComponent height={'400px'} items={items4} columns={3}/>
      </div>
      <div>
        <h1>Event Management</h1>
        <h3>Subheading</h3>
        <GridComponent height={'670px'} items={items5} columns={2} layoutType="right-large"/>
        <GridComponent height={'600px'} items={items6} columns={2} layoutType="left-large"/>
      </div>
    </div>
  )
}

export default Events
