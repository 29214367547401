import React, { useState, useEffect } from 'react';
import './ourservices.scss'
import Header from '../../components/header/Header'
import nav_img_1 from '../../images/services_nav_img_1.jfif'
import nav_img_2 from '../../images/services_nav_img_2.jpg'
import nav_img_3 from '../../images/services_nav_img_3.jpg'
import nav_img_4 from '../../images/services_nav_img_4.jfif'
import nav_img_5 from '../../images/services_nav_img_5.jfif'

import event_mgm_img1 from '../../images/management/event_mgm_img_1.jfif'
import event_mgm_img2 from '../../images/management/event_mgm_img_2.jfif'
import event_mgm_img3 from '../../images/management/event_mgm_img_3.jfif'

import travel_mgm_img1 from '../../images/management/travel_mgm_img_1.jfif'
import travel_mgm_img2 from '../../images/management/travel_mgm_img_2.jfif'
import travel_mgm_img3 from '../../images/management/travel_mgm_img_3.jfif'
import travel_mgm_img4 from '../../images/management/travel_mgm_img_4.jfif'

import risk_img1 from '../../images/management/risk_img1.jfif'
import risk_img2 from '../../images/management/risk_img2.jfif'
import risk_img3 from '../../images/management/risk_img3.jfif'
import risk_img4 from '../../images/management/risk_img4.jfif'
import { useTranslation } from 'react-i18next';

import vector from './Vector.svg'



function OurServices() {
    const { t, i18n } = useTranslation();
    const [activeSection, setActiveSection] = useState('');
    const [isMenuActive, setMenuActive] = useState(true);
    const [isPopoutMenuActive, setPopoutMenuActive] = useState(false);
    const [isManagChildHovered, setIsManagChildHovered] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const serviceSections = [
        { id: 'travel-management', title: t('servicesNavBtn1'), img: nav_img_1 },
        { id: 'event-management', title: t('servicesNavBtn2'), img: nav_img_2 },
        { id: 'travel-consulting', title: t('servicesNavBtn3'), img: nav_img_3 },
        { id: 'special-assistance', title: t('servicesNavBtn4'), img: nav_img_4 },
        { id: 'risk-management', title: t('servicesNavBtn5'), img: nav_img_5 },
    ];

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };
    const toggleMenu = (status) => {
        setMenuActive(status);
    };
    const togglePopoutMenu = (status) => {
        setPopoutMenuActive(status);
    };
    
    
    useEffect(() => {
        const handleResize = () => {
          setIsMobileView(window.innerWidth < 700);

        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);
   
    return (
        <div id="oursevices">
            <div className='white-container'></div>
            <div className="oursevices-nav">
                <h1><span>{t('our')}</span> {t('services')}</h1>
                <h1 className='mobile-nav-h'>{activeSection === 'travel-management' ? t('travelManagement') : activeSection === 'event-management' ? t('eventManagement') : activeSection === 'risk-management' ? t('riskManagement') : activeSection === 'special-assistance' ? t('specialAssistance') : activeSection === 'travel-consulting' ? t('travelConsultingServices') : (t('our') + ' ' + t('services'))}</h1>
                <div className={`gradient-element-1 ${activeSection === '' ? '' : 'active'}`}></div>
                <div className={`gradient-element-2 ${activeSection === '' ? 'active' : ''}`}></div>

                {serviceSections.map((section) => (
                    <div 
                        key={section.id}
                        className={`ourservices-nav-child ${activeSection === section.id ? 'active' : ''}`}
                        style={{ background: `url(${section.img})` }}
                    > 
                    <div className={`gradient-element-3 ${activeSection === section.id ? '' : 'active'}`}></div>
                        <button style={{ background: `${activeSection === section.id ? '#A6CEF4' : ''}` }} onClick={() => handleSectionChange(section.id)}>
                            {section.title}
                        </button>
                    </div>

                ))}

            </div >
            <div className={`vector-c ${(isPopoutMenuActive || activeSection === '') === false ? '' : 'active'}`} onClick={() => togglePopoutMenu(true)}>
                <img src={vector} alt="" />
                <div/>
                <div/>
            </div>
            <div className={`ourservices-nav-buttons ${isMenuActive === true ? '' : 'active'}`}>{serviceSections.map((section) => (
                <button style={{ background: `${activeSection === section.id ? '#A6CEF4' : ''}` }} onClick={() => (handleSectionChange(section.id), toggleMenu(false))}>
                    {section.title}
                </button>
            ))}
           
            </div>
            <div className={`ourservices-nav-buttons popout ${isPopoutMenuActive === true ? '' : 'active'}`}>{serviceSections.map((section) => (
                <button onClick={() => (handleSectionChange(section.id),togglePopoutMenu(false))}>
                    {section.title}
                </button>
            ))}
            
                <img src={vector} alt="" />
                
           
            </div>
            {activeSection === 'travel-management' && (
                <div id='travel-management' className='management-topic' style={{ marginBottom: (isManagChildHovered && isMobileView) ? '200px' : '100px' , marginLeft:'2%'}}>
                    <div className='management-topic-grid'>
                        <div onMouseEnter={() => setIsManagChildHovered(true)} onMouseLeave={() => setIsManagChildHovered(false)} className='management-topic-grid-child' style={{ background: `url(${travel_mgm_img1})` }}><h3>{t('travelManagementCard1H')}</h3><span className='arrs'><div/><div/></span><div className='management-topic-popout-text'>{t('travelManagementCard1P')}<ul><li>{t('travelManagementCard1Li1')}</li><li>{t('travelManagementCard1Li2')}</li><li>{t('travelManagementCard1Li3')}</li><li>{t('travelManagementCard1Li4')}</li></ul></div></div>
                        <div onMouseEnter={() => setIsManagChildHovered(true)} onMouseLeave={() => setIsManagChildHovered(false)} className='management-topic-grid-child' style={{ background: `url(${travel_mgm_img2})` }}><h3>{t('travelManagementCard2H')}</h3><span className='arrs'><div/><div/></span><div className='management-topic-popout-text'>{t('travelManagementCard2P')}<ul><li>{t('travelManagementCard2Li1')}</li><li>{t('travelManagementCard2Li2')}</li><li>{t('travelManagementCard2Li3')}</li></ul></div></div>
                        <div className='management-topic-grid-child c' style={{ background: `url(${travel_mgm_img3})` }}><h3>{t('travelManagementCard3H')}</h3><span className='arrs'><div/><div/></span><div className='management-topic-popout-text'>{t('travelManagementCard3P')}<ul><li>{t('travelManagementCard3Li1')}</li><li>{t('travelManagementCard3Li2')}</li><li>{t('travelManagementCard3Li3')}</li></ul></div></div>
                        <div className='management-topic-grid-child c' style={{ background: `url(${travel_mgm_img4})` }}><h3>{t('travelManagementCard4H')}</h3><span className='arrs'><div/><div/></span><div className='management-topic-popout-text'>{t('travelManagementCard4P')}<ul><li>{t('travelManagementCard4Li1')}</li><li>{t('travelManagementCard4Li2')}</li></ul></div></div>
                    </div>
                    <div className='management-topic-text'>
                        <h1>{t('travelManagement')}</h1>
                        <div>
                            <h2>{t('travelManagementSubHeading')}</h2>
                            <p>{t('travelManagementp1')}</p>
                        </div>
                        <p>{t('travelManagementp2')}</p>
                    </div>
                </div>)}
            {activeSection === 'event-management' && (
                <div id='event-management' className='management-topic' style={{ float: 'right' }}>

                    <div className='management-topic-text'>
                        <h1>{t('eventManagement')}</h1>
                        <div>
                            <h2>{t('eventManagementSubHeading')}</h2>
                            <p>{t('eventManagementp1')}</p>
                        </div>
                        <p>{t('eventManagementp2')}</p>
                    </div>
                    <div className='management-topic-grid' style={{ marginBottom: (isManagChildHovered && isMobileView) ? '150px' : '100px'}}>
                        <div onMouseEnter={() => setIsManagChildHovered(true)} onMouseLeave={() => setIsManagChildHovered(false)} className='management-topic-grid-child' style={{ background: `url(${event_mgm_img1})` }}><h3>{t('eventManagementCard1H')}</h3><span className='arrs'><div/><div/></span><div className='management-topic-popout-text'>{t('eventManagementCard1P')}<ul><li>{t('eventManagementCard1Li1')}</li><li>{t('eventManagementCard1Li2')}</li><li>{t('eventManagementCard1Li3')}</li><li>{t('eventManagementCard1Li4')}</li><li>{t('eventManagementCard1Li5')}</li></ul></div></div>
                        <div className='management-topic-grid-child' style={{ background: `url(${event_mgm_img2})` }}><h3>{t('eventManagementCard2H')}</h3><span className='arrs'><div/><div/></span><div className='management-topic-popout-text'>{t('eventManagementCard2P')}<ul><li>{t('eventManagementCard2Li1')}</li><li>{t('eventManagementCard2Li2')}</li><li>{t('eventManagementCard2Li3')}</li><li>{t('eventManagementCard2Li4')}</li></ul></div></div>
                        <div className='management-topic-grid-child c' style={{ background: `url(${event_mgm_img3})` }}><h3>{t('eventManagementCard3H')}</h3><span className='arrs'><div/><div/></span><div className='management-topic-popout-text'>{t('eventManagementCard2P')}<ul><li>{t('eventManagementCard3Li1')}</li><li>{t('eventManagementCard3Li2')}</li><li>{t('eventManagementCard3Li3')}</li><li>{t('eventManagementCard3Li4')}</li></ul></div></div>
                        <div className='management-topic-grid-child' style={{ background: `#A6CEF4` }}></div>
                    </div>
                </div>)}
            {activeSection === 'travel-consulting' && (
                <div id='travel-consulting' className='sevices-grid'>
                    <div className='sevices-grid-element'>
                        <div>
                            <h2>{t('travelConsultingServicesCard1H')}</h2>
                            <p>{t('travelConsultingServicesCard1P')}</p>
                        </div>
                        <div>
                            <h2>{t('travelConsultingServicesCard2H')}</h2>
                            <p>{t('travelConsultingServicesCard2P')}</p>
                        </div>
                        <div>
                            <h2>{t('travelConsultingServicesCard3H')}</h2>
                            <p>{t('travelConsultingServicesCard3P')}</p>
                        </div>
                        <div>
                            <h2>{t('travelConsultingServicesCard4H')}</h2>
                            <p>{t('travelConsultingServicesCard4P')}</p>
                        </div>
                        <div style={{ background: '#A6CEF4', border: '0' }}>

                        </div>
                        <div>
                            <h2>{t('travelConsultingServicesCard5H')}</h2>
                            <p>{t('travelConsultingServicesCard5P')}</p>
                        </div>
                    </div>
                    <div className='management-topic-text'>
                        <h1>{t('travelConsultingServices')}</h1>
                        <h2>{t('travelConsultingServicesSubHeading')}</h2>
                        <p>{t('travelConsultingServicesp1')}</p>
                        <p>{t('travelConsultingServicesp2')}</p>
                    </div>
                </div>)}
            {activeSection === 'special-assistance' && (
                <div id='special-assistance' className='sevices-grid'>
                    <div className='management-topic-text'>
                        <h1>{t('specialAssistance')}</h1>
                        <h2>{t('specialAssistanceSubHeading')}</h2>
                        <p>{t('specialAssistancep1')}</p>
                        <p>{t('specialAssistancep2')}</p>
                    </div>
                    <div className='sevices-grid-element-custom'>
                        <div>
                            <h2>{t('specialAssistanceCard1H')}</h2>
                            <p>{t('specialAssistanceCard1P')}</p>
                        </div>
                        <div>
                            <h2>{t('specialAssistanceCard2H')}</h2>
                            <p>{t('specialAssistanceCard2P')}</p>
                        </div>
                        <div>
                            <h2>{t('specialAssistanceCard3H')}</h2>
                            <p>{t('specialAssistanceCard3P')}</p>
                        </div>
                        <div>
                            <h2>{t('specialAssistanceCard4H')}</h2>
                            <p>{t('specialAssistanceCard4P')}</p>
                        </div>

                        <div>
                            <h2>{t('specialAssistanceCard5H')}</h2>
                            <p>{t('specialAssistanceCard5P')}</p>
                        </div>
                        <div style={{ background: '#A6CEF4', border: '0' }}>

                        </div>
                    </div>

                </div>)}
            {activeSection === 'risk-management' && (
                <div id='risk-management' className='risk-management'>
                    <div className='risk-management-topic'>
                        <h1>{t('riskManagement')}</h1>
                        <h2>{t('riskManagementSubHeading')}</h2>
                        <p>{t('riskManagementP')}</p>
                    </div>
                    <div className='risk-flex'>
                        <div className='risk-flex-child'>
                            <h2 style={{ background: `linear-gradient(to left, #A6CEF480 0%, #A6CEF480 100%), url(${risk_img1})` }}>{t('riskManagementCard1H')}</h2>
                            <p>{t('riskManagementCard1P')}</p>
                        </div>
                        <div className='risk-flex-child'>
                            <h2 style={{ background: `linear-gradient(to left, #A6CEF480 0%, #A6CEF480 100%), url(${risk_img2})` }}>{t('riskManagementCard2H')}</h2>
                            <p>{t('riskManagementCard2P')}
                                <ul><li>{t('riskManagementCard2Li1')} </li><li>{t('riskManagementCard2Li2')}</li></ul>
                            </p>
                        </div>
                        <div className='risk-flex-child'>
                            <h2 style={{ background: `linear-gradient(to left, #A6CEF480 0%, #A6CEF480 100%), url(${risk_img3})` }}>{t('riskManagementCard3H')}</h2>
                            <p>{t('riskManagementCard3P')}
                                <ul>
                                    <li>{t('riskManagementCard3Li1')}</li>
                                    <li>{t('riskManagementCard3Li2')}</li>
                                </ul>
                            </p>
                        </div>
                        <div className='risk-flex-child'>
                            <h2 style={{ background: `linear-gradient(to left, #A6CEF480 0%, #A6CEF480 100%), url(${risk_img4})` }}>{t('riskManagementCard4H')}</h2>
                            <p>{t('riskManagementCard4P')}
                                <ul>
                                    <li>{t('riskManagementCard4Li1')}</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>)}
        </div>

    )
}

export default OurServices
