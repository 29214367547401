import React from 'react'
import './inprogress.scss'
import img_1 from '../../images/404img_1.png'
import img_2 from '../../images/404img_2.png'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function InProgress() {
    const { t, i18n } = useTranslation();
  return (
    <div id='inprogres' style={{background:`linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 100%), url(${img_2})`}}>
        <h1><span>{t('SorryH')}</span></h1>
        <p>{t('sorryP')}</p>
        <Link to='/'>{t('BackToHomeBtn')} <span style={{fontSize:'25px'}}> →</span></Link>
        <img src={img_1} alt="404img_1" />
    </div>
  )
}

export default InProgress
