import React from 'react'
import YoutubeVideos from '../../components/YoutubeVideos/YoutubeVideos'
import Header from '../../components/header/Header'
import InProgress from '../../components/inprogress/InProgress'

function Blog() {
  return (
    <div>
     <YoutubeVideos channelId="UCpVm7bg6pXKo1Pr6k5kxG9A"/>
    </div>
  )
}

export default Blog
